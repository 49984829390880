import { useState, useEffect, useRef } from "react";
import axios from "axios";

import "./strapi-image.css";

export default function StrapiImage({
  baseUrl,
  image,
  styleImg,
  styleDiv,
  children,
}) {
  const divRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    if (!imageRef.current) return;
    if (imageRef.current.complete) {
      loaded();
    } else {
      imageRef.current.addEventListener("load", loaded);
    }
  }, [imageRef]);

  const loaded = () => {
    divRef?.current.classList.add("loaded");
    imageRef?.current.classList.add("loaded");
  };

  return (
    <div
      ref={divRef}
      className="blurred-div"
      style={{
        ...styleDiv,
        backgroundImage: `url(${baseUrl + image?.formats.thumbnail.url})`,
      }}
    >
      <img
        className="blurred-img"
        src={baseUrl + image?.url}
        loading="lazy"
        ref={imageRef}
        style={styleImg}
      />
      {children}
    </div>
  );
}
