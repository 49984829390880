export const accessControlProvider = {
  can: async ({ resource, action, params }) => {
    let user = localStorage.getItem("user");
    if (!user) user = localStorage.getItem("customer");
    if (user) user = JSON.parse(user);
    else
      return Promise.resolve({
        can: false,
        reason: "Unauthorized",
      });
    let accessArray = params?.resource?.options?.access?.split(" ") || [];
    // check if it is a customer page
    if (action == "customer" && user.role.type == "authenticated")
      return Promise.resolve({ can: true });
    // all access pages
    if (resource == "dashboard" && user.role.type != "authenticated")
      return Promise.resolve({ can: true });
    // default admin privileges
    if (user.role.type == "admin") return { can: true };
    // check if user has access to the page
    if (accessArray.includes(user.role.type))
      return Promise.resolve({ can: true });
    return Promise.resolve({
      can: false,
      reason: "Unauthorized",
    });
  },
};
