import { useState, useEffect } from "react";
import { TOKEN_KEY, API_URL } from "./constants";

// refine
import {
  ErrorComponent,
  ReadyPage,
  CssBaseline,
  createTheme,
  ThemeProvider,
  GlobalStyles,
  RefineSnackbarProvider,
  notificationProvider,
} from "@pankod/refine-mui";

// refine utils
import { Refine, Authenticated, CanAccess } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { DataProvider } from "@pankod/refine-strapi-v4";
import { authProvider } from "authProvider";
import { accessControlProvider } from "accessControlProvider";

// utils
import { AuthProvider } from "contexts/AuthContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "utils/axios";

// custom pages and components
import CustomLayout from "./components/CustomLayout";
import LoginPage from "./components/user/LoginPage";
import RegisterPage from "components/user/RegisterPage";
import PasswordForgot from "components/user/PasswordForgot";
import PasswordReset from "components/user/PasswordReset";
import Dashboard from "pages/Dashboard";
import Settings from "pages/Settings";
import Unauthorized from "pages/unauthorized";
import NotFound from "pages/customer/notFound";

// email templates
import EmailTemplatesIcon from "@mui/icons-material/EmailRounded";
import EmailTemplatesList from "pages/email-templates/List";
import EmailTemplatesCreate from "pages/email-templates/Create";
import EmailTemplatesEdit from "pages/email-templates/Edit";

// shop resources
import GiteRoundedIcon from "@mui/icons-material/GiteRounded";
import ShopList from "pages/shops/List";
import ShopCreate from "pages/shops/Create";
import ShopEdit from "pages/shops/Edit";

// packages
import CardGiftcardRoundedIcon from "@mui/icons-material/CardGiftcardRounded";
import PackageList from "pages/packages/List";
import PackageCreate from "pages/packages/Create";
import PackageEdit from "pages/packages/Edit";

import ValidateSubscription from "pages/validateSubscription";

// customer pages (public)
import CustomerLogin from "pages/customer/customerLogin";
import ShopView from "pages/customer/shopView";
import PackView from "pages/customer/packageView";
import Privacy from "pages/Privacy";
import ProfileView from "pages/customer/profileView";
import SubscriptionsView from "pages/customer/subscriptionsView";
import AccountDeleted from "pages/customer/accountDeleted";

export const themeOptions = {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 15,
        },
      },
    },
  },
  palette: {
    mode: "light",
    background: {
      default: "#fffff1",
    },
    primary: {
      main: "#632705",
    },
    secondary: {
      main: "#b75800",
    },
    info: {
      main: "#edf2f4",
    },
  },
};

const theme = createTheme(themeOptions);
const queryClient = new QueryClient();
window.queryClient = queryClient;

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
      <RefineSnackbarProvider>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Refine
              routerProvider={{
                ...routerProvider,
                routes: [
                  {
                    element: <RegisterPage />,
                    path: "/register",
                    layout: false,
                  },
                  {
                    element: <PasswordForgot />,
                    path: "/forgot-password",
                    layout: false,
                  },
                  {
                    element: <PasswordReset />,
                    path: "/reset-password",
                    layout: false,
                  },
                  {
                    element: <NotFound />,
                    path: "/not-found",
                    layout: false,
                  },
                  {
                    element: (
                      <Authenticated>
                        <Settings />
                      </Authenticated>
                    ),
                    path: "/settings",
                    layout: true,
                  },
                  {
                    element: <ShopView />,
                    path: "/shop/:id",
                    layout: false,
                  },
                  {
                    element: (
                      <Authenticated>
                        <ValidateSubscription />
                      </Authenticated>
                    ),
                    path: "/subscription/:id",
                    layout: true,
                  },
                  {
                    element: <PackView />,
                    path: "/packs/:id",
                    layout: false,
                  },
                  {
                    element: <CustomerLogin />,
                    path: "/customer-login",
                    layout: false,
                  },
                  {
                    element: (
                      <CanAccess action="customer" fallback={<Unauthorized />}>
                        <ProfileView />
                      </CanAccess>
                    ),
                    path: "/profile",
                    layout: false,
                  },
                  {
                    element: (
                      <CanAccess action="customer" fallback={<Unauthorized />}>
                        <SubscriptionsView />
                      </CanAccess>
                    ),
                    path: "/subscriptions",
                    layout: false,
                  },
                  {
                    element: <Privacy />,
                    path: "/privacy",
                    layout: false,
                  },
                  {
                    element: <AccountDeleted />,
                    path: "/account-deleted",
                    layout: false,
                  },
                ],
              }}
              resources={[
                {
                  name: "shops",
                  icon: <GiteRoundedIcon />,
                  options: { label: "Shops", access: "owner admin" },
                  list: ShopList,
                  create: ShopCreate,
                  edit: ShopEdit,
                },
                {
                  name: "packages",
                  icon: <CardGiftcardRoundedIcon />,
                  options: { label: "Packages", access: "owner" },
                  list: PackageList,
                  create: PackageCreate,
                  edit: PackageEdit,
                },
                {
                  name: "email-templates",
                  icon: <EmailTemplatesIcon />,
                  options: { label: "Email Templates", access: "admin" },
                  list: EmailTemplatesList,
                  create: EmailTemplatesCreate,
                  edit: EmailTemplatesEdit,
                },
              ]}
              authProvider={authProvider}
              accessControlProvider={accessControlProvider}
              dataProvider={DataProvider(API_URL + "/api", axios)}
              notificationProvider={notificationProvider}
              Title={({ collapsed }) => (
                <div
                  style={{
                    height: 200,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {collapsed && (
                    <img src="/logo.png" alt="Logo" style={{ width: "50px" }} />
                  )}
                  {!collapsed && (
                    <img
                      src="/logo.png"
                      alt="Logo"
                      style={{ width: "100px" }}
                    />
                  )}
                </div>
              )}
              Layout={CustomLayout}
              DashboardPage={Dashboard}
              ReadyPage={ReadyPage}
              LoginPage={LoginPage}
              catchAll={<ErrorComponent />}
            />
          </AuthProvider>
        </QueryClientProvider>
      </RefineSnackbarProvider>
      <ToastContainer />
    </ThemeProvider>
  );
};

export default App;
