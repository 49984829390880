import {
  Grid,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Button,
} from "@pankod/refine-mui";
import { useNavigate } from "@pankod/refine-react-router-v6";

import StrapiImage from "components/strapi-image";

import { API_URL } from "constants";

export default function PackageCard({ pack }) {
  const navigate = useNavigate();
  return (
    <Grid item xs={12} sx={{ p: 2 }}>
      <Card sx={{ borderRadius: 5, height: "100%" }}>
        <CardHeader
          title={pack.name}
          onClick={() => {
            navigate("/packs/" + pack.id);
          }}
        />
        {pack?.image && (
          <StrapiImage
            image={pack.image}
            baseUrl={API_URL}
            styleDiv={{
              width: "100%",
              height: 194,
            }}
            styleImg={{
              objectFit: "cover",
            }}
          />
        )}
        {!pack?.image && (
          <img
            src={`/love-letter.png`}
            alt="logo"
            style={{
              width: "100%",
              height: 194,
              objectFit: "contain",
            }}
          />
        )}
        {/* <CardContent>
          <Typography variant="body2" color="text.secondary">
            {pack.description}
          </Typography>
        </CardContent> */}
        <CardActions>
          <Button
            fullWidth
            size="small"
            color="success"
            onClick={() => {
              navigate("/packs/" + pack.id);
            }}
          >
            Learn More
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
}
