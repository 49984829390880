import React, { useState, useEffect } from "react";
import {
  CanAccess,
  useTitle,
  useMenu,
  useTranslate,
  useRouterContext,
  useRefineContext,
  useIsExistAuthentication,
  useLogout,
} from "@pankod/refine-core";
import {
  Box,
  Drawer,
  MuiList,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Tooltip,
  IconButton,
  Button,
  Title as DefaultTitle,
} from "@pankod/refine-mui";
import {
  Dashboard,
  ListOutlined,
  ExpandLess,
  ExpandMore,
  ChevronLeft,
  ChevronRight,
  Logout,
  MenuRounded,
  Settings,
} from "@mui/icons-material";

import axios from "utils/axios";

export default function CustomMenu() {
  const [collapsed, setCollapsed] = useState(false);
  const [opened, setOpened] = useState(false);
  const [user, setUser] = useState(null);

  const isExistAuthentication = useIsExistAuthentication();
  const { mutate: logout } = useLogout();

  const drawerWidth = () => {
    if (collapsed) return 64;
    return 200;
  };

  const t = useTranslate();

  const { Link } = useRouterContext();
  const { hasDashboard } = useRefineContext();

  const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
  const Title = useTitle();

  const [open, setOpen] = useState(true);

  useEffect(() => {
    setOpen((previousOpen) => {
      const previousOpenKeys = Object.keys(previousOpen);
      const uniqueKeys = new Set([...previousOpenKeys, ...defaultOpenKeys]);
      const uniqueKeysRecord = Object.fromEntries(
        Array.from(uniqueKeys.values()).map((key) => [key, true])
      );
      return uniqueKeysRecord;
    });
  }, [defaultOpenKeys]);

  useEffect(() => {
    const fetchUser = async () => {
      const { data } = await axios.get("/api/users/me");
      setUser(data);
    };
    fetchUser();
  }, []);

  const RenderToTitle = Title ?? DefaultTitle;
  const accountSetup = user?.account?.details_submitted;

  const handleClick = (key) => {
    setOpen({ ...open, [key]: !open[key] });
  };

  const renderTreeView = (tree, selectedKey) => {
    return tree.map((item) => {
      const { icon, label, route, name, children, parentName, options } = item;
      const isOpen = open[route || ""] || false;

      const isSelected = route === selectedKey;
      const isNested = !(parentName === undefined);

      if (children.length > 0) {
        return (
          <div key={route}>
            <Tooltip
              title={label ?? name}
              placement="right"
              disableHoverListener={!collapsed}
              arrow
            >
              <ListItemButton
                disabled={!accountSetup}
                onClick={() => {
                  if (collapsed) {
                    setCollapsed(false);
                    if (!isOpen) {
                      handleClick(route || "");
                    }
                  } else {
                    handleClick(route || "");
                  }
                }}
                sx={{
                  pl: isNested ? 4 : 2,
                  justifyContent: "center",
                  "&.Mui-selected": {
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    backgroundColor: isSelected
                      ? "transparent"
                      : "secondary.main",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    justifyContent: "center",
                    minWidth: 36,
                  }}
                >
                  {icon ?? <ListOutlined />}
                </ListItemIcon>
                <ListItemText
                  primary={label}
                  primaryTypographyProps={{
                    noWrap: true,
                    fontSize: "14px",
                    fontWeight: isSelected ? "bold" : "normal",
                  }}
                />
                {!collapsed && (isOpen ? <ExpandLess /> : <ExpandMore />)}
              </ListItemButton>
            </Tooltip>
            {!collapsed && (
              <Collapse in={open[route || ""]} timeout="auto" unmountOnExit>
                <MuiList component="div" disablePadding>
                  {renderTreeView(children, selectedKey)}
                </MuiList>
              </Collapse>
            )}
          </div>
        );
      }

      return (
        <CanAccess
          key={route}
          resource={name.toLowerCase()}
          action="list"
          params={{
            resource: {
              name,
              label,
              options,
            },
          }}
        >
          <Tooltip
            title={label ?? name}
            placement="right"
            disableHoverListener={!collapsed}
            arrow
          >
            <ListItemButton
              disabled={!accountSetup}
              component={Link}
              to={route}
              selected={isSelected}
              onClick={() => {
                setOpened(false);
              }}
              sx={{
                pl: isNested ? 4 : 2,
                py: isNested ? 1.25 : 1,
                justifyContent: "center",
                "&.Mui-selected": {
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                  backgroundColor: isSelected
                    ? "transparent"
                    : "secondary.main",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  justifyContent: "center",
                  minWidth: 36,
                  color: isSelected ? "secondary.main" : "primary.main",
                }}
              >
                {icon ?? <ListOutlined />}
              </ListItemIcon>
              <ListItemText
                primary={label}
                primaryTypographyProps={{
                  noWrap: true,
                  fontSize: "14px",
                  fontWeight: isSelected ? "bold" : "normal",
                  color: isSelected ? "secondary.main" : "primary.main",
                }}
              />
            </ListItemButton>
          </Tooltip>
        </CanAccess>
      );
    });
  };

  const drawer = (
    <MuiList disablePadding sx={{ mt: 1, color: "primary.main" }}>
      {hasDashboard ? (
        <Tooltip
          title={t("dashboard.title", "Dashboard")}
          placement="right"
          disableHoverListener={!collapsed}
          arrow
        >
          <ListItemButton
            component={Link}
            to="/"
            selected={selectedKey === "/"}
            onClick={() => {
              setOpened(false);
            }}
            sx={{
              pl: 2,
              py: 1,
              "&.Mui-selected": {
                "&:hover": {
                  backgroundColor: "transparent",
                },
                backgroundColor: "transparent",
              },
              justifyContent: "center",
            }}
          >
            <ListItemIcon
              sx={{
                justifyContent: "center",
                minWidth: 36,
                color: selectedKey === "/" ? "secondary.main" : "primary.main",
              }}
            >
              <Dashboard />
            </ListItemIcon>
            <ListItemText
              primary={t("dashboard.title", "Dashboard")}
              primaryTypographyProps={{
                noWrap: true,
                fontSize: "14px",
                fontWeight: selectedKey === "/" ? "bold" : "normal",
                color: selectedKey === "/" ? "secondary.main" : "primary.main",
              }}
            />
          </ListItemButton>
        </Tooltip>
      ) : null}

      {renderTreeView(menuItems, selectedKey)}
      {isExistAuthentication && (
        <>
          <Tooltip
            title={t("buttons.settings", "Settings")}
            placement="right"
            disableHoverListener={!collapsed}
            arrow
          >
            <ListItemButton
              component={Link}
              to="/settings"
              key="settings"
              sx={{ justifyContent: "center" }}
            >
              <ListItemIcon
                sx={{
                  justifyContent: "center",
                  minWidth: 36,
                }}
              >
                <Settings />
              </ListItemIcon>
              <ListItemText
                primary={t("buttons.settings", "Settings")}
                primaryTypographyProps={{
                  noWrap: true,
                  fontSize: "14px",
                }}
              />
            </ListItemButton>
          </Tooltip>
          <Tooltip
            title={t("buttons.logout", "Logout")}
            placement="right"
            disableHoverListener={!collapsed}
            arrow
          >
            <ListItemButton
              key="logout"
              onClick={() => logout()}
              sx={{ justifyContent: "center" }}
            >
              <ListItemIcon
                sx={{
                  justifyContent: "center",
                  minWidth: 36,
                  color: "error.main",
                }}
              >
                <Logout />
              </ListItemIcon>
              <ListItemText
                primary={t("buttons.logout", "Logout")}
                primaryTypographyProps={{
                  noWrap: true,
                  fontSize: "14px",
                  color: "error.main",
                }}
              />
            </ListItemButton>
          </Tooltip>
        </>
      )}
    </MuiList>
  );

  return (
    <>
      <Box
        sx={{
          width: { xs: drawerWidth() },
          display: {
            xs: "none",
            md: "block",
          },
          transition: "width 0.3s ease",
        }}
      />
      <Box
        component="nav"
        sx={{
          position: "fixed",
          zIndex: 1101,
          width: { sm: drawerWidth() },
          display: "flex",
        }}
      >
        <Drawer
          variant="temporary"
          open={opened}
          onClose={() => setOpened(false)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              width: 256,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: 3,
            }}
          >
            <RenderToTitle collapsed={false} />
          </Box>
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          PaperProps={{ elevation: 1 }}
          sx={{
            display: { xs: "none", md: "flex" },
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              overflow: "hidden",
              transition: "width 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
              justifyContent: "center",
            },
          }}
          open
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <RenderToTitle collapsed={collapsed} />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            {drawer}
          </Box>
          <Button
            sx={{
              background: "rgba(0,0,0,.5)",
              color: "primary.main",
              textAlign: "center",
              borderRadius: 0,
              borderTop: "1px solid #ffffff1a",
            }}
            fullWidth
            size="large"
            onClick={() => setCollapsed((prev) => !prev)}
          >
            {collapsed ? <ChevronRight /> : <ChevronLeft />}
          </Button>
        </Drawer>
      </Box>
      <Box
        sx={{
          display: { xs: "block", md: "none" },
          position: "fixed",
          top: "64px",
          left: "0px",
          borderRadius: "0 6px 6px 0",
          bgcolor: "secondary.main",
          zIndex: 1199,
          width: "36px",
        }}
      >
        <IconButton
          sx={{ color: "#fff", width: "36px" }}
          onClick={() => setOpened((prev) => !prev)}
        >
          <MenuRounded />
        </IconButton>
      </Box>
    </>
  );
}
