import { useMemo } from "react";
import {
  EditButton,
  DeleteButton,
  Stack,
  Typography,
  useDataGrid,
  DataGrid,
  List,
} from "@pankod/refine-mui";
import { CanAccess } from "@pankod/refine-core";
import Unauthorized from "pages/unauthorized";

export default function CompList() {
  const columns = useMemo(
    () => [
      { field: "name", headerName: "Name", flex: 1, minWidth: 300 },
      {
        field: "cost",
        headerName: "Price",
        flex: 1,
        minWidth: 100,
        renderCell(params) {
          return (Number(params.row.cost) / 100).toFixed(2) + "£";
        },
      },
      { field: "uses", headerName: "Uses Per Month", flex: 1, minWidth: 100 },
      {
        headerName: "Actions",
        field: "actions",
        minWidth: 50,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton hideText recordItemId={params.row.id} />
              <DeleteButton hideText recordItemId={params.row.id} />
            </Stack>
          );
        },
      },
    ],
    []
  );

  const { dataGridProps, sortingMode, sortModel, onSortModelChange } =
    useDataGrid();

  return (
    <CanAccess
      resource="packages"
      action="list"
      fallback={<Unauthorized />}
      params={{
        resource: {
          options: {
            label: "Packages",
            access: "owner",
          },
        },
      }}
    >
      <List
        resource="packages"
        createButtonProps={{ size: "small" }}
        headerProps={{
          title: <Typography variant="h5">Packages</Typography>,
        }}
      >
        <DataGrid
          {...dataGridProps}
          columns={columns}
          sortingMode={sortingMode}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          autoHeight
        />
      </List>
    </CanAccess>
  );
}
