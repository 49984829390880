import { useEffect, useState } from "react";

import { alpha, useTheme } from "@mui/material";

import { format } from "date-fns";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const datasetOptions = {
  fill: true,
  borderWidth: 1,
  pointRadius: 2,
  tension: 0.4,
  pointBorderWidth: 4,
};
const options = {
  plugins: {
    legend: {
      display: false,
    },
    tooltips: {
      displayColors: false,
      callbacks: {
        title: () => "",
        filter: () => false,
        label: (tooltipItem) => {
          let label = tooltipItem.label;

          if (label) {
            label += " - ";
          }

          return `${label}$${Math.round(tooltipItem.yLabel * 100) / 100}`;
        },
      },
    },
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      display: true,
      grid: {
        display: false,
      },
      ticks: {
        beginAtZero: true,
        precision: 0,
      },
    },
    y: {
      display: true,
      grid: {
        display: false,
      },
      ticks: {
        beginAtZero: true,
        precision: 0,
      },
    },
  },
};

const LineChart = ({ data, label, height }) => {
  const [dataSet, setDataSet] = useState(null);
  const { palette: colors } = useTheme();

  const datasetData = data.map((point) => point.count);

  useEffect(() => {
    let labelList = data.map((item) => {
      let date = new Date(item.createdAt);
      return format(date, "dd-MM-yyyy");
    });
    setDataSet({
      labels: labelList,
      datasets: [
        {
          label: label,
          data: datasetData,
          backgroundColor: alpha(colors.primary.main, 0.2),
          borderColor: colors.primary.main,
          ...datasetOptions,
        },
      ],
    });
  }, []);

  return (
    <div style={{ height: height || "100%" }}>
      {dataSet && <Line data={dataSet} options={options} height={height} />}
    </div>
  );
};

export default LineChart;
