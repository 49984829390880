import { useState } from "react";

// mui

import {
  FormControlLabel,
  Card,
  Button,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Checkbox,
} from "@pankod/refine-mui";

// utils
import useAuth from "contexts/AuthContext";

const LoginForm = ({ onComplete, onError }) => {
  const { login } = useAuth();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");

    attemptLogin(email, password);
  };

  const attemptLogin = async (email, password) => {
    try {
      let newUser = await login(email, password);
      if (newUser != null) {
        if (onComplete) onComplete();
      }
    } catch (error) {
      // console.log(error);
      if (onError) onError(error.error);
    }
  };

  return (
    <Grid item>
      <Card sx={{ p: 5 }}>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            fullWidth
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Login
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/forgot-password" variant="body2">
                Forgot your password?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Grid>
  );
};

const RegisterForm = ({ onComplete, onError }) => {
  const { register, logout } = useAuth();
  const [acceptedTOS, setAcceptedTOS] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let username = data.get("username");
    let email = data.get("email");
    let password = data.get("password");

    if (
      username &&
      username.length > 0 &&
      email &&
      email.length > 0 &&
      password &&
      password.length > 0
    )
      attemptRegistration(username, email, password);
  };

  const attemptRegistration = async (username, email, password) => {
    logout();
    try {
      let newUser = await register(username, email, password);
      if (newUser != null) {
        if (onComplete) onComplete();
      }
    } catch (error) {
      console.log(error);
      if (onError) onError(error.error);
    }
  };

  return (
    <Grid item>
      <Card sx={{ p: 5 }}>
        <Typography component="h1" variant="h5">
          Register
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name="acceptTOS"
                    id="acceptTOS"
                    onChange={(e) => setAcceptedTOS(e.target.checked)}
                  />
                }
                label="I Accept the"
              />
              <Link href="/privacy" variant="body1">
                Privacy Policy
              </Link>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={!acceptedTOS}
          >
            Register
          </Button>
        </Box>
      </Card>
    </Grid>
  );
};

export default function LoginOrRegister({ onComplete, onError }) {
  const [isLogin, setIsLogin] = useState(true);

  return (
    <Grid item>
      <Grid item xs={12} container justifyContent="space-evenly">
        <Grid item xs={6}>
          <Button fullWidth onClick={() => setIsLogin(true)} disabled={isLogin}>
            Login
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            onClick={() => setIsLogin(false)}
            disabled={!isLogin}
          >
            Register
          </Button>
        </Grid>
      </Grid>
      {isLogin ? (
        <LoginForm onComplete={onComplete} onError={onError} />
      ) : (
        <RegisterForm onComplete={onComplete} onError={onError} />
      )}
    </Grid>
  );
}
