import { useState, useEffect } from "react";

import {
  Grid,
  Card,
  Typography,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Icon,
  borderRadius,
} from "@pankod/refine-mui";
import Loading from "react-loading-components";
import NavBar from "components/Navbar";
import QRCode from "react-qr-code";

import StrapiImage from "components/strapi-image";

import axios from "utils/axios";
import { APP_URL, API_URL } from "constants";
import { toast } from "react-toastify";
import moment from "moment";

export default function PackView(props) {
  const [loading, setLoading] = useState(true);
  const [subscriptions, setSubscriptions] = useState([]);
  const [showingCode, setShowingCode] = useState(null);

  const loadData = async () => {
    try {
      setLoading(true);
      let { data } = await axios.get("/api/subscriptions");
      if (data) {
        setSubscriptions(data);
        setLoading(false);
      }
    } catch (error) {
      toast.error(
        "Something went wrong while loading your subscriptions, please try again later"
      );
      console.log(error);
      window.open("/customer-login", "_self");
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  if (loading)
    return (
      <Grid
        container
        sx={{ height: "100vh" }}
        justifyContent="center"
        alignItems="center"
      >
        <Loading type="grid" width={100} height={100} fill="#8A56FF" />
      </Grid>
    );

  return (
    <Grid container justifyContent="center">
      <NavBar title="LovYa" />
      <Grid container mt="68px" justifyContent="center">
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          sx={{ m: 2 }}
          container
          justifyContent="center"
        >
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={async () => {
              let res = await axios.post(
                API_URL + "/api/create-portal-session",
                {
                  returnUrl: APP_URL + "/subscriptions",
                }
              );
              let invoice = res.data;
              window.open(invoice, "_blank");
            }}
          >
            Manage Subscriptions
          </Button>
        </Grid>

        {subscriptions.map((sub) => (
          <Grid item xs={12} md={6} lg={4} key={sub.id}>
            <Card sx={{ p: 2, m: 2, borderRadius: 10 }}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                gap={1}
                onClick={() =>
                  window.open(
                    APP_URL + "/shop/" + sub?.package?.shop?.shop_id,
                    "_self"
                  )
                }
              >
                <Typography variant="h5">{sub?.package?.shop?.name}</Typography>
                <Icon>open_in_new</Icon>
                <Divider sx={{ width: "100%", mb: 3 }} />
              </Grid>
              <Grid container>
                <Grid item xs={5}>
                  {sub?.package?.image && (
                    <StrapiImage
                      image={sub.package.image}
                      baseUrl={API_URL}
                      alt="logo"
                      styleDiv={{
                        width: 100,
                        height: 100,
                        borderRadius: "50%",
                      }}
                      styleImg={{
                        width: 100,
                        height: 100,
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                  )}
                  {!sub?.package?.image && (
                    <img
                      src={`/love-letter.png`}
                      alt="logo"
                      style={{
                        width: 100,
                        height: 100,
                        objectFit: "contain",
                        borderRadius: "50%",
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="h6">{sub.package.name}</Typography>
                  <Typography variant="body1">Status: {sub.status}</Typography>
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
              <Grid container justifyContent="space-evenly">
                <Grid item xs={5}>
                  <Typography variant="body1" align="center">
                    Start Date: {moment(sub.createdAt).format("DD/MM/YYYY")}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body1" align="center">
                    End Date:{" "}
                    {moment(sub.createdAt).add(1, "month").format("DD/MM/YYYY")}
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
              <Grid container>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                    onClick={() => setShowingCode(sub)}
                  >
                    Show Code
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                    onClick={async () => {
                      let res = await axios.get(
                        API_URL + "/api/invoice/" + sub.invoice_id
                      );
                      let invoice = res.data;
                      window.open(invoice, "_blank");
                    }}
                  >
                    View Invoice
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={showingCode}
        onClose={() => setShowingCode(null)}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: 8,
          },
        }}
      >
        <h1
          style={{ textAlign: "center" }}
        >{`${showingCode?.package.name}`}</h1>
        <Divider sx={{ width: "100%" }} />
        <DialogContent sx={{ padding: 3 }}>
          <Grid container justifyContent="center" alignItems="center">
            {showingCode && (
              <QRCode
                value={`${APP_URL}/subscription/${showingCode.subscription_id}`}
              />
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
