import { useEffect, useMemo, useState } from "react";
import {
  EditButton,
  DeleteButton,
  Stack,
  Button,
  Dialog,
  DialogContent,
  Typography,
  useDataGrid,
  DataGrid,
  List,
  Divider,
  Grid,
} from "@pankod/refine-mui";

import ReactMarkdown from "react-markdown";
import QRCode from "react-qr-code";
import { getMe } from "utils/utils";
import { API_URL, APP_URL } from "constants";

export default function CompList() {
  const [user, setUser] = useState(null);
  const [showingCode, setShowingCode] = useState(null);
  const [columns, setColumns] = useState([]);

  const setupColumns = async () => {
    const me = await getMe();
    setUser(me);
    if (me?.role?.name === "Owner") {
      setColumns([
        { field: "name", headerName: "Name", flex: 1, minWidth: 300 },
        {
          field: "qr_code",
          headerName: "QR Code",
          flex: 1,
          minWidth: 300,
          renderCell: function render(params) {
            return (
              <Button
                onClick={() => setShowingCode(params.row)}
                variant="contained"
              >
                SHOW QR CODE
              </Button>
            );
          },
        },
        {
          headerName: "Actions",
          field: "actions",
          minWidth: 50,
          renderCell: function render(params) {
            return (
              <Stack direction="row" spacing={1}>
                <EditButton hideText recordItemId={params.row.id} />
                <DeleteButton hideText recordItemId={params.row.id} />
              </Stack>
            );
          },
        },
      ]);
    } else if (me?.role?.name === "Agent") {
      setColumns([
        { field: "name", headerName: "Name", flex: 1, minWidth: 300 },
        {
          field: "qr_code",
          headerName: "QR Code",
          flex: 1,
          minWidth: 300,
          renderCell: function render(params) {
            return (
              <Button
                onClick={() => setShowingCode(params.row)}
                variant="contained"
              >
                SHOW QR CODE
              </Button>
            );
          },
        },
        {
          headerName: "Subscribers",
          flex: 1,
          minWidth: 200,
          renderCell: function render(params) {
            return (
              <h1>
                {params.row.packages.reduce(
                  (acc, curr) => acc + curr.subscribers.length,
                  0
                )}
              </h1>
            );
          },
        },
      ]);
    }
  };

  useEffect(() => {
    setupColumns();
  }, []);

  const { dataGridProps, sortingMode, sortModel, onSortModelChange } =
    useDataGrid({
      metaData: {
        populate: "*",
      },
    });

  return (
    <List
      resource="shops"
      createButtonProps={{ size: "small" }}
      headerProps={{
        title: <Typography variant="h5">Shops</Typography>,
      }}
      canCreate={user?.role?.name == "Owner"}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        sortingMode={sortingMode}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        autoHeight
      />
      <Dialog open={showingCode} onClose={() => setShowingCode(null)}>
        <img
          src={`${API_URL}${showingCode?.image.url}`}
          alt="logo"
          style={{
            height: 200,
            objectFit: "cover",
          }}
        />
        <h1 style={{ textAlign: "center" }}>{`${showingCode?.name}`}</h1>
        <Divider sx={{ width: "100%" }} />
        <DialogContent
          sx={{ padding: 3 }}
          // onClick={() =>
          //   window.open(APP_URL + "/shop/" + showingCode.shop_id, "_blank")
          // }
        >
          <Grid container justifyContent="center" alignItems="center">
            {showingCode && (
              <QRCode value={`${APP_URL}/shop/${showingCode.shop_id}`} />
            )}
          </Grid>
          <Grid item xs={12} container justifyContent="center" sx={{ p: 2 }}>
            <ReactMarkdown>{`${showingCode?.description}`}</ReactMarkdown>
          </Grid>
        </DialogContent>
      </Dialog>
    </List>
  );
}
