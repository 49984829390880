import { useState } from "react";

import { Grid, Typography } from "@pankod/refine-mui";
import NavBar from "components/Navbar";
import LoginOrRegister from "components/LoginOrRegister";

import { toast } from "react-toastify";
import useAuth from "contexts/AuthContext";

export default function AccountDeleted(props) {
  return (
    <Grid container justifyContent="center">
      <NavBar title="LovYa" />
      <Grid container mt="90px">
        <Grid item container xs={12} justifyContent="center">
          <img src="/user.png" alt="user" width="200px" height="200px" />
          <Typography variant="h4" align="center" mt="20px">
            Hello Stranger!
          </Typography>
          <Typography variant="h6" align="center" mt="20px">
            Your account has been deleted. You can now close this tab.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
