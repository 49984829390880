import React, { useState, useEffect } from "react";

import { useRegister, useLogout } from "@pankod/refine-core";
import {
  Grid,
  Button,
  TextField,
  Box,
  Card,
  Divider,
} from "@pankod/refine-mui";
import Anim from "components/Anim";

import { useParams } from "utils/utils";
import { toast } from "react-toastify";
import axios from "utils/axios";
import { useForm } from "react-hook-form";

export default function Register() {
  const { mutate: register } = useRegister();
  const { mutate: logout } = useLogout();
  const [params, paramsReady] = useParams();

  const [selectedRole, setSelectedRole] = useState("Owner");
  const [agent, setAgent] = useState(null);

  const setupAgent = async (agentID) => {
    let { data } = await axios.get("/api/agent/" + agentID);
    setAgent(data);
  };

  useEffect(() => {
    if (!paramsReady) return;
    const role = params.get("role");
    if (role) {
      if (role != "Owner" && role != "Agent") {
        toast.error(
          "There was an error with your registration session, please double check the link you received"
        );
      }
      setSelectedRole(role);
    }
    // fetch agent if present
    let agentID = params.get("agentUID");
    if (agentID) {
      setupAgent(agentID);
    }
  }, [paramsReady]);

  const onSubmit = (formData) => {
    logout();
    register(
      {
        first_name: formData.first_name,
        last_name: formData.last_name,
        birth_date: formData.birth_date,
        phone: formData.phone,
        email: formData.email,
        password: formData.password,
        roleName: params.get("role") || selectedRole || "Owner",
        agentUID: agent?.user_uid || null,
      },
      {
        onSuccess: () => {
          toast.success("Registration successful!");
          // login(
          //   {
          //     email: e.target.email.value,
          //     password: e.target.password.value,
          //   },
          //   {
          //     onSuccess: () => {
          //       // go to the page the user was trying to access before being redirected to the login page
          //       const to = params?.get("to");
          //       if (to) {
          //         window.location = to;
          //       }
          //     },
          //   }
          // );
        },
      }
    );
  };

  const { register: registerForm, handleSubmit } = useForm();

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        alignItems="center"
        style={{
          height: "100vh",
        }}
      >
        <Grid
          item
          xs={11}
          container
          justifyContent="center"
          alignItems="center"
          rowSpacing={3}
        >
          <Grid item md={5} xs={12} container justifyContent="center">
            <Anim dir="left">
              <Card sx={{ borderRadius: "50%" }}>
                <Grid container spacing={3} p={3}>
                  <Grid item xs={12} container justifyContent="center">
                    <img
                      src="/logo.png"
                      alt="Logo"
                      style={{ height: 200, objectFit: "contain" }}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Anim>
          </Grid>
          <Grid item md={6} xs={12} container justifyContent="center">
            <Anim dir="right">
              <Card sx={{ borderRadius: 7, padding: 3, minWidth: "33vw" }}>
                <Grid container spacing={3} p={3}>
                  <Grid item container justifyContent="center">
                    <h1 level={3}>{`Register as an ${selectedRole}`}</h1>
                  </Grid>
                  {agent && (
                    <Grid item container justifyContent="center">
                      <h4>{`Registering through: ${agent.username}`}</h4>
                    </Grid>
                  )}
                </Grid>
                <Box
                  component="form"
                  onSubmit={handleSubmit(onSubmit)}
                  sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                  autoComplete="off"
                >
                  <TextField
                    {...registerForm("first_name", { required: true })}
                    name="first_name"
                    label="First Name"
                    rules={[{ required: true }]}
                    placeholder="First Name"
                    required
                    defaultValue={params?.get("first_name")}
                  />
                  <TextField
                    {...registerForm("last_name", { required: true })}
                    name="last_name"
                    label="Last Name"
                    rules={[{ required: true }]}
                    placeholder="Last Name"
                    required
                    defaultValue={params?.get("last_name")}
                  />
                  <TextField
                    {...registerForm("birth_date", { required: true })}
                    name="birth_date"
                    label="Date of Birth"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    rules={[{ required: true }]}
                    required
                    defaultValue={params?.get("birth_date")}
                    type="date"
                  />
                  <Divider sx={{ my: 1 }} />
                  <TextField
                    {...registerForm("email", { required: true })}
                    name="email"
                    label="Email"
                    rules={[{ required: true }]}
                    placeholder="Email"
                    required
                    defaultValue={params?.get("email")}
                  />
                  <TextField
                    {...registerForm("phone", { required: true })}
                    name="phone"
                    label="Phone Number"
                    rules={[{ required: true }]}
                    placeholder="Phone Number"
                    required
                    defaultValue={params?.get("phone")}
                    type="tel"
                  />
                  <TextField
                    {...registerForm("password", { required: true })}
                    name="password"
                    label="Password"
                    rules={[{ required: true }]}
                    placeholder="Password"
                    required
                    defaultValue={params?.get("password")}
                  />
                  <Button type="submit" variant="contained">
                    Register
                  </Button>
                  <div style={{ marginBottom: "12px" }}>
                    <a
                      style={{
                        float: "right",
                        fontSize: "14px",
                        color: "#00bcd4",
                      }}
                      href={
                        "/login" +
                        (params?.get("to") ? "?to=" + params?.get("to") : "")
                      }
                    >
                      Already have an account? Login here
                    </a>
                  </div>
                </Box>
              </Card>
            </Anim>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
