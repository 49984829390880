import { useEffect } from "react";

import { Grid, Typography } from "@pankod/refine-mui";
import NavBar from "components/Navbar";
import LoginOrRegister from "components/LoginOrRegister";

import { toast } from "react-toastify";
import useAuth from "contexts/AuthContext";

export default function CustomerLogin(props) {
  const { user } = useAuth();

  useEffect(() => {
    localStorage.setItem("user-type", "customer");
    if (user && user.role.name === "Authenticated") {
      window.open("/subscriptions", "_self");
    }
  }, [user]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "100vh",
      }}
    >
      <NavBar title="LovYa" />
      <Grid container mt="90px">
        <Grid item container xs={12} justifyContent="center">
          <Grid item container xs={12} justifyContent="center">
            <img src="/user.png" alt="user" width="200px" height="200px" />
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            <Typography variant="h4" align="center" mt="20px">
              Hello Stranger!
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} justifyContent="center">
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            sx={{ m: 2 }}
            container
            justifyContent="center"
          >
            <LoginOrRegister
              onComplete={() => {
                toast.success("You are logged in!");
                window.open("/subscriptions", "_self");
              }}
              onError={(error) => {
                console.log(error);
                const errMessage = error?.message;
                const emailError = errMessage === "User already exists";

                toast.error(
                  emailError || errMessage || "There was an error logging in"
                );
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
