import { useState, useEffect } from "react";

import { useParams } from "@pankod/refine-react-router-v6";
import { Grid, Divider } from "@pankod/refine-mui";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ReactMarkdown from "react-markdown";

import NavBar from "components/Navbar";
import PackageCard from "components/shops/packageCard";

import axios from "utils/axios";
import { API_URL } from "constants";
import StrapiImage from "components/strapi-image";

export default function ShopView(props) {
  const { id } = useParams();

  const [shop, setShop] = useState(null);

  const loadShopData = async (shopID) => {
    try {
      let { data } = await axios.get("/api/shop-data/" + shopID);
      if (data) {
        console.log(data.active);
        if (data.active) setShop(data);
        else {
          window.open("/not-found", "_self");
        }
      }
    } catch (error) {
      console.error(error);
      window.open("/not-found", "_self");
    }
  };

  useEffect(() => {
    if (id) loadShopData(id);
  }, [id]);

  return (
    <Grid container justifyContent="space-evenly">
      {shop && <NavBar title="LovYa" shopInfo={shop} />}
      <Grid item xs={12} container justifyContent="center">
        {shop && (
          <StrapiImage
            image={shop?.image}
            baseUrl={API_URL}
            styleDiv={{
              marginTop: "68px",
              width: "100%",
              height: 200,
            }}
            styleImg={{
              objectFit: "cover",
            }}
          />
        )}
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        {shop && <h1 style={{ textAlign: "center" }}>{`${shop?.name}`}</h1>}
      </Grid>
      <Divider sx={{ width: "100%" }} />
      <Grid item xs={12} container justifyContent="center" sx={{ p: 2 }}>
        <ReactMarkdown>{`${shop?.description}`}</ReactMarkdown>
      </Grid>
      <Divider sx={{ width: "100%" }} />
      <Grid item xs={12} container justifyContent="center">
        {shop && <h2 style={{ textAlign: "center" }}>{`Our Subscriptions`}</h2>}
      </Grid>
      <Divider sx={{ width: "100%" }} />
      {shop?.packages && (
        <Carousel
          autoPlay
          infiniteLoop
          swipeable
          emulateTouch
          width="100vw"
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          showIndicators={false}
          stopOnHover
        >
          {shop?.packages?.map((pack, index) => (
            <PackageCard pack={pack} key={index} />
          ))}
        </Carousel>
      )}
    </Grid>
  );
}
