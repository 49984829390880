import { useEffect, useState } from "react";

import { Stack, Box, TextField, Switch } from "@pankod/refine-mui";

import UploadZone from "components/UploadZone";

export default function CompFields({
  register,
  control,
  errors,
  getValues,
  setError,
  setValue,
  watch,
}) {
  const [shopActive, setShopActive] = useState(getValues("active"));

  const imageInput = watch("image");

  useEffect(() => {
    setShopActive(getValues("active"));
  }, [watch("active")]);

  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column" }}
      autoComplete="off"
    >
      <Stack spacing={2}>
        <UploadZone
          register={register}
          errors={errors}
          setError={setError}
          setValue={setValue}
          defaultValue={imageInput}
          fieldName="image"
          required
        />
        <TextField
          {...register("name", { required: "Name is required" })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.name}
          helperText={errors.name?.message}
          required
          fullWidth
          label="Name"
          autoFocus
        />
        <TextField
          {...register("description", { required: "Description is required" })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.description}
          helperText={errors.description?.message}
          required
          fullWidth
          label="Description"
          multiline
          rows={6}
        />
        <TextField
          {...register("preferred_payment_day", {
            required: "Preferred payment day is required",
          })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.preferred_payment_day}
          helperText={errors.preferred_payment_day?.message}
          required
          fullWidth
          label="Preferred payment day"
          type="number"
          min={1}
          max={25}
        />
        {/* ---- Switch ---- */}
        <Stack direction="row" alignItems="center" spacing={2} mt={3}>
          Shop Is Active
          <Switch
            {...register("active", {})}
            control={control}
            checked={shopActive}
            onChange={(e) => {
              setShopActive(e.target.checked);
            }}
          />
        </Stack>
      </Stack>
    </Box>
  );
}
