import { TextField, Autocomplete } from "@pankod/refine-mui";

import { Controller } from "@pankod/refine-react-hook-form";

export default function ({
  name,
  label,
  control,
  required,
  autocompleteProps,
}) {
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: required ? `${label} is required` : false }}
      render={({ field }) => (
        <Autocomplete
          {...autocompleteProps}
          {...field}
          value={field.value ? field.value : ""}
          onChange={(_, value) => {
            field.onChange(value);
          }}
          getOptionLabel={(item) => {
            return item.name ? item.name : "";
          }}
          isOptionEqualToValue={(option, value) =>
            value === undefined || option.id === value.id
          }
          renderInput={(params) => (
            <TextField
              InputLabelProps={{ shrink: true }}
              {...params}
              label={label}
              margin="normal"
              variant="outlined"
              required
            />
          )}
        />
      )}
    />
  );
}
