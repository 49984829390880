import { Grid, Card, Typography } from "@pankod/refine-mui";

export default function Unauthorized() {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100vh" }}
    >
      <Grid item xs={11} md={5}>
        <Card sx={{ borderRadius: 10, height: "100%" }}>
          <Grid
            item
            xs={12}
            container
            alignItems="space-between"
            justifyContent="center"
            p={3}
            sx={{ height: "100%" }}
          >
            <Grid item xs={12} container justifyContent="center">
              <img
                src="/warning.png"
                alt="cancel"
                style={{ width: 300, height: 300, marginBottom: 10 }}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <Typography align="center" mb={2}>
                I am sorry but you are not allowed to be here...
              </Typography>
              <Typography align="center">
                If you got here by mistake just go back
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
