import { useState } from "react";

import {
  Stack,
  Box,
  TextField,
  Button,
  Grid,
  Dialog,
} from "@pankod/refine-mui";

import { Controller } from "@pankod/refine-react-hook-form";

import Editor from "components/EmailEditor";

export default function CompFields({
  register,
  control,
  errors,
  getValues,
  setValue,
  record,
}) {
  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column" }}
      autoComplete="off"
    >
      <Stack spacing={3} direction={{ xs: "column", md: "row" }} mb={3}>
        <TextField
          {...register("message_type", {
            required: "Message Type is required",
          })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.message_type}
          helperText={errors.message_type?.message}
          required
          fullWidth
          label="Message Type"
        />

        <TextField
          {...register("subject", { required: "Subject is required" })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.subject}
          helperText={errors.subject?.message}
          required
          fullWidth
          label="Subject"
        />
      </Stack>

      <Grid item xs={12}>
        <Editor
          template={record}
          onSave={(json, html) => {
            setValue("html", html);
            setValue("json", JSON.stringify(json));
          }}
        />
      </Grid>
    </Box>
  );
}
