import React, { useRef, useEffect } from "react";

import { Button, Grid } from "@pankod/refine-mui";

import EmailEditor from "react-email-editor";

const Editor = ({ template, onSave }) => {
  const emailEditorRef = useRef(null);

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      if (onSave) onSave(design, html);
    });
  };

  const onLoad = () => {
    if (
      emailEditorRef.current &&
      emailEditorRef.current.editor &&
      template &&
      template.json &&
      template.json.length > 0
    ) {
      emailEditorRef.current.editor.loadDesign(JSON.parse(template.json));
    }
  };

  useEffect(() => {
    onLoad();
  }, [template, emailEditorRef.current]);

  const onReady = () => {
    // editor is ready
    console.log("onReady");
  };

  return (
    <Grid sx={{ overflow: "hidden" }}>
      <EmailEditor
        ref={emailEditorRef}
        onLoad={onLoad}
        onReady={onReady}
        minHeight="80vh"
      />

      <Grid container justifyContent="flex-end">
        <Button
          color="success"
          variant="contained"
          onClick={exportHtml}
          fullWidth
          sx={{ m: 2 }}
        >
          {template && template.json ? "Update" : "Save"} Design
        </Button>
      </Grid>
    </Grid>
  );
};

export default Editor;
