import { useState, useEffect } from "react";

import { useParams } from "@pankod/refine-react-router-v6";
import {
  Grid,
  Divider,
  LoadingButton,
  Typography,
  Card,
} from "@pankod/refine-mui";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import StrapiImage from "components/strapi-image";

import axios from "utils/axios";
import { API_URL } from "constants";
import { toast } from "react-toastify";

export default function ValidateSubscription(props) {
  const { id } = useParams();

  const [subscription, setSubscription] = useState(null);
  const [validating, setValidating] = useState(false);

  const loadSubscriptionData = async (subID) => {
    try {
      let { data } = await axios.get("/api/load-subscription/" + subID);
      if (data) {
        setSubscription(data);
      }
    } catch (error) {
      toast.error(
        "Something went wrong while loading this subscription, please try again later"
      );
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) loadSubscriptionData(id);
  }, [id]);

  return (
    <Grid container justifyContent="space-evenly">
      {/* {subscription && (
        <NavBar title="LovYa" shopInfo={subscription.package.shop} />
      )} */}
      {subscription?.package?.image && (
        <StrapiImage
          image={subscription.package.image}
          baseUrl={API_URL}
          alt="logo"
          styleDiv={{
            width: 200,
            height: 200,
            marginTop: "1rem",
            borderRadius: "30px",
            overflow: "hidden",
          }}
          styleImg={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      )}
      {!subscription?.package?.image && (
        <div
          style={{
            height: 200,
            width: 200,
            marginTop: "1rem",
            borderRadius: "30px",
            overflow: "hidden",
          }}
        >
          <img
            src={`/love-letter.png`}
            alt="logo"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </div>
      )}
      <Grid item xs={12} container justifyContent="center">
        {subscription && (
          <h1
            style={{ textAlign: "center" }}
          >{`${subscription?.package.name}`}</h1>
        )}
      </Grid>
      <Divider sx={{ width: "100%" }} />
      <Grid item xs={12} container justifyContent="center" sx={{ p: 2 }}>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          Owned by: {subscription?.user.username}
        </Typography>
      </Grid>
      <Divider sx={{ width: "100%" }} />
      <Grid item xs={12} container justifyContent="center" sx={{ p: 2 }}>
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            textDecoration:
              subscription?.status == "cancelled" ? "line-through" : "none",
          }}
        >
          Redemptions Available:{" "}
          {subscription?.package.uses - subscription?.redemptions.length} /{" "}
          {subscription?.package.uses}
        </Typography>
      </Grid>
      <LoadingButton
        fullWidth
        variant="contained"
        color="primary"
        loading={validating}
        disabled={
          subscription?.package.uses - subscription?.redemptions.length <= 0 ||
          subscription?.status == "cancelled"
        }
        onClick={async () => {
          setValidating(true);
          try {
            let { data } = await axios.post("/api/validate-subscription/", {
              subscription_id: subscription.subscription_id,
            });
            switch (data.status) {
              case "success":
                toast.success("Subscription validated successfully");
                loadSubscriptionData(id);
                break;
              case "error":
                toast.error(data.message);
                break;
            }
            setValidating(false);
          } catch (error) {
            toast.error(
              "Something went wrong while validating this subscription, please try again later"
            );
            setValidating(false);
          }
        }}
        sx={{ mx: 4 }}
      >
        Redeem
      </LoadingButton>
      {subscription?.status == "cancelled" && (
        <Typography
          variant="h6"
          sx={{ textAlign: "center", color: "red", mt: 4 }}
        >
          This subscription has been cancelled
        </Typography>
      )}
      <Divider sx={{ width: "100%", my: 4 }} />

      <Typography variant="h5" sx={{ textAlign: "center" }}>
        Redemption History
      </Typography>
      <Grid item xs={12} container justifyContent="center" sx={{ p: 2 }}>
        {subscription?.redemptions.map((redemption, i) => (
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            sx={{ p: 1 }}
            key={i}
          >
            <Card sx={{ width: "100%", p: 2, borderRadius: 10 }}>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                {new Date(redemption?.createdAt).toLocaleDateString()}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
