import { useSearchParams } from "@pankod/refine-react-router-v6";
import { useState, useEffect } from "react";
import axios from "./axios";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import { toast } from "react-toastify";

export const normalizeData = (data) => {
  // following code credits goes to roelbeerens see: https://forum.strapi.io/t/discussion-regarding-the-complex-response-structure-for-rest-graphql-developer-experience/13400/9
  const isObject = (data) =>
    Object.prototype.toString.call(data) === "[object Object]";

  const flatten = (data) => {
    if (!data.attributes) return data;

    return {
      id: data.id,
      ...data.attributes,
    };
  };

  if (Array.isArray(data)) {
    return data.map((item) => normalizeData(item));
  }

  if (isObject(data)) {
    if (Array.isArray(data.data)) {
      data = [...data.data];
    } else if (isObject(data.data)) {
      data = flatten({ ...data.data });
    } else if (data.data === null) {
      data = null;
    } else {
      data = flatten(data);
    }

    for (const key in data) {
      data[key] = normalizeData(data[key]);
    }

    return data;
  }

  return data;
};

export const useParams = () => {
  let par = useSearchParams();

  const [paramsReady, setParamsReady] = useState(false);
  const [params, setParams] = useState(null);
  const [paramsCount, setParamsCount] = useState(0);

  useEffect(() => {
    if (params != null || !par[0]) return;
    par = par[0];
    setParamsReady(true);
    setParams(par);
    if (Array.from(par).length == 0) return;
    setParamsCount(Array.from(par).length);
  }, [par]);

  return [params, paramsReady, paramsCount];
};

export const getMe = async () => {
  let res = await axios.get("/api/users/me");
  return res.data;
};

export const getStripeConnect = () => {
  // We use `useState` to ensure the Connect instance is only initialized once
  const fetchClientSecret = async () => {
    // Fetch the AccountSession client secret
    const { data } = await axios.get("/api/connect-stats");
    if (!data) {
      // Handle errors on the client side here
      toast.error("An error occurred while fetching the client secret");
      return undefined;
    } else {
      const { client_secret: clientSecret } = data;
      return clientSecret;
    }
  };

  return loadConnectAndInitialize({
    // This is your test publishable API key.
    publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
    fetchClientSecret: fetchClientSecret,
  });
};
