import { Edit } from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";
import { useOne } from "@pankod/refine-core";
import { useParams } from "react-router-dom";

import Fields from "./Fields";

export default function CompEdit() {
  const {
    refineCore: { formLoading, queryResult },
    saveButtonProps,
    register,
    control,
    formState: { errors },
    getValues,
    setValue,
    setError,
    watch,
  } = useForm();

  const params = useParams();
  const record = useOne({
    resource: "shops",
    id: params.id,
  });

  return (
    <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Fields
        register={register}
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        setError={setError}
        // record={record.data.data}
        watch={watch}
      />
    </Edit>
  );
}
