import { useState, useEffect } from "react";

import {
  Grid,
  Card,
  TextField,
  Button,
  Stack,
  Divider,
  Typography,
} from "@pankod/refine-mui";

import { useLogout } from "@pankod/refine-core";
import { toast } from "react-toastify";
import axios from "utils/axios";

import { getStripeConnect } from "utils/utils";
import {
  ConnectComponentsProvider,
  ConnectAccountManagement,
  ConnectDocuments,
} from "@stripe/react-connect-js";

export default function Settings() {
  const { mutate: logout } = useLogout();

  const [user, setUser] = useState(null);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [stripeConnectInstance] = useState(() => {
    return getStripeConnect();
  });

  useEffect(() => {
    const fetchUser = async () => {
      const { data } = await axios.get("/api/users/me");
      setUser(data);
    };
    fetchUser();
  }, []);

  return (
    <Grid container gap={5} justifyContent="space-evenly">
      <Grid item md={5} xs={11} sx={{ mt: -7, mb: 3 }}>
        <Card sx={{ borderRadius: 10, height: "100%" }}>
          <Grid
            item
            xs={12}
            container
            alignItems="space-between"
            p={3}
            sx={{ height: "100%" }}
          >
            <Grid item xs={12} container justifyContent="center">
              {user && (
                <Typography
                  variant="h3"
                  style={{ paddingTop: 10 }}
                >{`Welcome ${user.username}`}</Typography>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>

      <Grid item xs={12} container justifyContent="space-evenly">
        {/* ACCOUNT --------------------------------------------------------------------------- */}
        <Grid item xs={11} mb={2}>
          <Card sx={{ borderRadius: 5, height: "100%" }}>
            <Grid
              item
              xs={12}
              container
              gap={3}
              p={3}
              sx={{ height: "100%" }}
              justifyContent="center"
            >
              <Typography variant="h4">Account Info</Typography>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ pointerEvents: "none" }}
                fullWidth
                label="Username"
                value={user?.username}
                variant="outlined"
              />
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ pointerEvents: "none" }}
                fullWidth
                label="Email"
                value={user?.email}
                variant="outlined"
              />
              <Divider
                sx={{
                  width: "100%",
                  my: 1,
                }}
              />
              <Typography variant="h4">Change Password</Typography>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                label="Current Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
              />
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                label="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                variant="outlined"
              />
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                label="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                variant="outlined"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  if (newPassword === confirmPassword) {
                    try {
                      let res = await axios.post("/api/auth/change-password", {
                        currentPassword: password,
                        password: newPassword,
                        passwordConfirmation: confirmPassword,
                      });
                      console.log(res.data);
                      toast.success("Password Updated");
                    } catch (error) {
                      console.log(error);
                      toast.error("Error Updating Password");
                    }
                    setPassword("");
                    setNewPassword("");
                    setConfirmPassword("");
                  }
                }}
              >
                Update Password
              </Button>
            </Grid>
          </Card>
        </Grid>
        {/* STRIPE CONNECT --------------------------------------------------------------------- */}
        <Grid item xs={11} mb={2}>
          <Card sx={{ borderRadius: 5, height: "100%" }}>
            <Grid
              item
              xs={12}
              container
              justifyContent="space-around"
              alignItems="center"
              p={3}
              sx={{ height: "100%" }}
            >
              <Typography variant="h4">Stripe Connect</Typography>
              <ConnectComponentsProvider
                connectInstance={stripeConnectInstance}
              >
                <ConnectAccountManagement />
                <ConnectDocuments />
              </ConnectComponentsProvider>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      <Grid item xs={12} container justifyContent="space-evenly" my={2}>
        {/* DELETE ACCOUNT --------------------------------------------------------------------- */}
        <Grid item md={7} xs={11} mb={2}>
          <Card sx={{ borderRadius: 5, height: "100%" }}>
            <Grid
              item
              xs={12}
              container
              justifyContent="space-around"
              alignItems="center"
              p={3}
              sx={{ height: "100%" }}
            >
              <Grid item md={6} xs={12} sx={{ width: "100%" }}>
                <Typography variant="h4">DANGER ZONE</Typography>
              </Grid>
              <Grid item md={4} xs={12} sx={{ width: "100%" }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="error"
                  onClick={async () => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete your account? This process is irreversible, all your subscribers will be notified of the deletion of your account and all your data will be deleted."
                      )
                    ) {
                      try {
                        let res = await axios.delete(
                          "/api/delete-owner-account"
                        );
                        if (res.data.status == "success") {
                          toast.success("Account Deleted");
                          logout();
                        }
                      } catch (error) {
                        console.log(error);
                        toast.error("Error Deleting Account");
                      }
                    }
                  }}
                >
                  Delete Account
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}
