import { useEffect, useState } from "react";

import {
  Stack,
  Box,
  TextField,
  Switch,
  useAutocomplete,
} from "@pankod/refine-mui";

import CustomAutoComplete from "components/CustomAutoComplete";
import UploadZone from "components/UploadZone";

export default function CompFields({
  register,
  control,
  errors,
  getValues,
  setError,
  setValue,
  watch,
}) {
  const [shopActive, setShopActive] = useState(getValues("active"));
  const { autocompleteProps: shops } = useAutocomplete({
    resource: "shops",
  });

  const imageInput = watch("image");

  useEffect(() => {
    setShopActive(getValues("active"));
  }, [watch("active")]);

  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column" }}
      autoComplete="off"
    >
      <Stack spacing={2}>
        <UploadZone
          register={register}
          errors={errors}
          setError={setError}
          setValue={setValue}
          defaultValue={imageInput}
          fieldName="image"
          required
        />
        <CustomAutoComplete
          name="shop"
          label="Shop"
          control={control}
          required={true}
          autocompleteProps={shops}
        />
        <TextField
          {...register("name", { required: "Name is required" })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.name}
          helperText={errors.name?.message}
          required
          fullWidth
          label="Name"
          autoFocus
        />
        <TextField
          {...register("description", { required: "Description is required" })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.description}
          helperText={errors.description?.message}
          required
          fullWidth
          label="Description"
          multiline
          rows={6}
        />
        <TextField
          {...register("cost", {
            required: "Package Price is required",
          })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.cost}
          helperText={errors.cost?.message}
          required
          fullWidth
          label="Package Price"
          type="number"
        />
        <TextField
          {...register("uses", {
            required: "Uses For Each Month is required",
          })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.uses}
          helperText={errors.uses?.message}
          required
          fullWidth
          label="Uses For Each Month"
          type="number"
        />
        {/* ---- Switch ---- */}
        <Stack direction="row" alignItems="center" spacing={2} mt={3}>
          Package Is Active
          <Switch
            {...register("active")}
            control={control}
            checked={shopActive}
            onChange={(e) => {
              setShopActive(e.target.checked);
            }}
          />
        </Stack>
      </Stack>
    </Box>
  );
}
