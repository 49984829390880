import { Grid, Card, Typography } from "@pankod/refine-mui";

export default function NotFound() {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100vh" }}
    >
      <Grid item xs={11} md={5}>
        <Card sx={{ borderRadius: 10, height: "100%" }}>
          <Grid
            item
            xs={12}
            container
            alignItems="space-between"
            justifyContent="center"
            p={3}
            sx={{ height: "100%" }}
          >
            <Grid item xs={12} container justifyContent="center">
              <img
                src="/oops.png"
                alt="cancel"
                style={{ width: 300, height: 300, marginBottom: 10 }}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <Typography align="center" mb={2}>
                The shop/package you are looking for is either inactive or not
                existent
              </Typography>
              <Typography align="center">You can close this window</Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
