import { useState, useEffect } from "react";

import {
  Grid,
  Card,
  Typography,
  Button,
  TextField,
  Divider,
} from "@pankod/refine-mui";
import { LoadingButton } from "@pankod/refine-mui";
import Loading from "react-loading-components";
import NavBar from "components/Navbar";

import axios from "utils/axios";
import { API_URL, APP_URL } from "constants";
import { toast } from "react-toastify";
import useAuth from "contexts/AuthContext";

export default function ProfileView(props) {
  const { user, logout } = useAuth();

  const [loading, setLoading] = useState(true);
  const [subscriptions, setSubscriptions] = useState([]);
  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const loadData = async () => {
    try {
      setLoading(true);
      let { data } = await axios.get("/api/subscriptions");
      if (data) {
        setSubscriptions(data);
        setActiveSubscriptions(data.filter((sub) => sub.status === "active"));
        setLoading(false);
      }
    } catch (error) {
      toast.error(
        "Something went wrong while loading your subscriptions, please try again later"
      );
      setSubscriptions(null);
      console.log(error);
      setLoading(false);
      if (!user) window.open("/customer-login", "_self");
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  if (loading)
    return (
      <Grid
        container
        sx={{ height: "100vh" }}
        justifyContent="center"
        alignItems="center"
      >
        <Loading type="grid" width={100} height={100} fill="#8A56FF" />
      </Grid>
    );

  return (
    <Grid container justifyContent="center">
      <NavBar title="LovYa" />
      <Grid
        container
        mt="90px"
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: "80vh" }}
        gap={5}
      >
        <Grid item container xs={12} justifyContent="center">
          <img src="/user.png" alt="user" width="200px" height="200px" />
          <Grid item container xs={12} justifyContent="center">
            <Typography variant="h4" align="center" mt="20px">
              Hello {user?.username}!
            </Typography>
          </Grid>
          {/* {subscriptions && (
            <Grid item container xs={12} justifyContent="center">
              <Typography variant="h6" align="center" mt="20px">
                {" "}
                You have {activeSubscriptions.length} active subscription
                {activeSubscriptions.length > 1
                  ? "s"
                  : activeSubscriptions.length == 0
                  ? "s"
                  : ""}
              </Typography>
            </Grid>
          )}
          {!subscriptions && (
            <Grid item container xs={12} justifyContent="center">
              <Typography variant="h6" align="center" mt="20px">
                We had trouble loading your subscriptions, please try again
                later
              </Typography>
            </Grid>
          )} */}
        </Grid>

        <Grid item xs={12} container justifyContent="space-evenly">
          {/* ACCOUNT --------------------------------------------------------------------------- */}
          <Grid item xs={11} mb={2}>
            <Card sx={{ borderRadius: 5, height: "100%" }}>
              <Grid
                item
                xs={12}
                container
                gap={3}
                p={3}
                sx={{ height: "100%" }}
                justifyContent="center"
              >
                <Typography variant="h4">Account Info</Typography>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ pointerEvents: "none" }}
                  fullWidth
                  label="Username"
                  value={user?.username}
                  variant="outlined"
                />
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ pointerEvents: "none" }}
                  fullWidth
                  label="Email"
                  value={user?.email}
                  variant="outlined"
                />
                <Divider
                  sx={{
                    width: "100%",
                    my: 1,
                  }}
                />
                <Typography variant="h4">Change Password</Typography>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  label="Current Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  variant="outlined"
                />
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  label="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  variant="outlined"
                />
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  label="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  variant="outlined"
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    if (newPassword === confirmPassword) {
                      try {
                        let res = await axios.post(
                          "/api/auth/change-password",
                          {
                            currentPassword: password,
                            password: newPassword,
                            passwordConfirmation: confirmPassword,
                          }
                        );
                        console.log(res.data);
                        toast.success("Password Updated");
                      } catch (error) {
                        console.log(error);
                        toast.error("Error Updating Password");
                      }
                      setPassword("");
                      setNewPassword("");
                      setConfirmPassword("");
                    }
                  }}
                >
                  Update Password
                </Button>
              </Grid>
            </Card>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} lg={4} sx={{ m: 2 }}>
          <Typography variant="h4" color="red" align="center" sx={{ mb: 1 }}>
            Delete Account
          </Typography>
          {subscriptions && activeSubscriptions.length > 0 && (
            <Card sx={{ borderRadius: 5, height: "100%", p: 2 }}>
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  <Typography variant="h6" align="center" mt="20px">
                    You must cancel your subscriptions before deleting your
                    account
                  </Typography>
                </Grid>
                <Grid item xs={12} mt="20px">
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={async () => {
                      try {
                        let res = await axios.post(
                          API_URL + "/api/create-portal-session",
                          {
                            customer_id: user.stripe_customer_id,
                          }
                        );
                        window.open(res.data, "_self");
                      } catch (error) {
                        toast.error(
                          "Something went wrong, please try again later"
                        );
                      }
                    }}
                  >
                    Manage Subscriptions
                  </Button>
                </Grid>
              </Grid>
            </Card>
          )}
          {subscriptions && activeSubscriptions.length == 0 && (
            <LoadingButton
              loading={loading}
              disabled={!subscriptions || activeSubscriptions.length > 0}
              variant="contained"
              color="error"
              fullWidth
              onClick={async () => {
                try {
                  if (
                    window.confirm(
                      "Are you sure you want to delete your account?"
                    )
                  ) {
                    await axios.delete(API_URL + "/api/delete-account");
                    logout();
                    window.open(APP_URL + "/account-deleted", "_self");
                  }
                } catch (error) {
                  toast.error("Something went wrong, please try again later");
                }
              }}
            >
              Delete Account
            </LoadingButton>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
