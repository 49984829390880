import { useState, useEffect } from "react";

import {
  Grid,
  Card,
  Button,
  Alert,
  CardContent,
  CardActions,
  LoadingButton,
  Dialog,
  Tabs,
  Tab,
  Box,
  MuiList,
  ListItem,
  ListItemButton,
} from "@pankod/refine-mui";
import QRCode from "react-qr-code";
import Loading from "react-loading-components";
import LineChart from "components/LineChart";

import {
  ConnectPayments,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";

import { useGetIdentity } from "@pankod/refine-core";
import axios from "utils/axios";
import { APP_URL } from "constants";
import { toast } from "react-toastify";
import { getStripeConnect } from "utils/utils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function Dashboard() {
  const { data: user } = useGetIdentity();

  const [qrUrl, setQrUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [requestingPayout, setRequestingPayout] = useState(false);

  // stats
  const [businessStats, setBusinessStats] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);

  // We use `useState` to ensure the Connect instance is only initialized once
  const [stripeConnectInstance] = useState(() => {
    return getStripeConnect();
  });

  const fetchUser = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get("/api/users/me");
      const { data: statsData } = await axios.get("/api/business-stats");
      setBusinessStats(statsData);
      setLoading(false);
      if (data.role.name == "Agent")
        setQrUrl(`${APP_URL}/register?role=Owner&agentUID=${data.user_uid}`);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const accountSetup = user?.account?.details_submitted;

  if (loading)
    return (
      <Grid
        container
        sx={{ height: "100vh" }}
        justifyContent="center"
        alignItems="center"
      >
        <Loading type="grid" width={100} height={100} fill="#8A56FF" />
      </Grid>
    );

  const earningCard = (title, value, color) => {
    return (
      <Grid item xs={11} md={3} height={300} sx={{ p: 3 }}>
        <Card sx={{ borderRadius: 10, height: "100%", width: "100%" }}>
          <CardContent sx={{ height: 200 }}>
            <h1
              style={{
                textAlign: "center",
                fontSize: "4em",
                height: "30%",
                color,
              }}
            >
              £{value}
            </h1>
            <CardActions>
              <h3 style={{ textAlign: "center", width: "100%" }}>{title}</h3>
            </CardActions>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  return (
    <Grid container rowSpacing={5} justifyContent="space-evenly">
      <Grid item md={5} xs={11} sx={{ mt: -7, mb: 3 }}>
        <Card sx={{ borderRadius: 10, height: "100%" }}>
          <Grid
            item
            xs={12}
            container
            alignItems="space-between"
            p={3}
            sx={{ height: "100%" }}
          >
            <Grid item xs={12} container justifyContent="center">
              {user && (
                <h1
                  style={{ paddingTop: 10, textAlign: "center" }}
                >{`Welcome ${user.username}`}</h1>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>

      {user?.role.name == "Agent" && (
        <Grid item container justifyContent="center" mb={5}>
          <Card sx={{ borderRadius: 10, height: "100%" }}>
            <Grid
              item
              xs={12}
              container
              justifyContent={"center"}
              alignItems="center"
            >
              <h1 style={{ paddingTop: 10 }}>Share your QR code</h1>
            </Grid>
            <Grid container justifyContent="space-between" p={3}>
              <Grid
                item
                xs={12}
                md={6}
                container
                justifyContent="center"
                alignItems="center"
              >
                <MuiList>
                  <ListItemButton
                    onClick={() => {
                      window.open(qrUrl, "_blank");
                    }}
                  >
                    Register Shop
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      navigator.clipboard.writeText(qrUrl);
                      toast.success("Copied to clipboard");
                    }}
                  >
                    Copy To Clipboard
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      window.open(
                        "mailto:?subject=Join LovYa&body=Join LovYa by clicking this link: " +
                          qrUrl
                      );
                    }}
                  >
                    Share Via Email
                  </ListItemButton>
                </MuiList>
              </Grid>
              <Grid item xs={12} md={6} container justifyContent="center">
                <Grid item xs={12} container justifyContent="center">
                  {accountSetup && <QRCode value={qrUrl} />}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      )}

      <Grid container justifyContent="space-around">
        <Dialog open={!accountSetup} maxWidth="xl">
          <Grid item xs={12} container justifyContent="center" mt={2} p={2}>
            <img
              src="/setup.png"
              width="100%"
              height={200}
              style={{ objectFit: "contain" }}
            />
          </Grid>
          {/* Warning if the user has not submitted the account details */}
          <Grid item xs={12} container justifyContent="center" mb={2}>
            <Alert severity="error">
              You must setup your payout account details before you can start
              using LovYa!
            </Alert>
          </Grid>
          {user && (
            <Grid item xs={11} container justifyContent="center" m={2}>
              <Button
                fullWidth
                onClick={async () => {
                  let res = await axios.get("/api/account-setup");
                  window.open(res.data, "_self");
                }}
                color={"success"}
                variant="outlined"
              >
                {`${accountSetup ? "Manage" : "Setup"} Account`}
              </Button>
            </Grid>
          )}
        </Dialog>

        <Grid item md={10} xs={11}>
          {businessStats && (
            <Grid container rowSpacing={3}>
              <Grid item xs={12}>
                <Card sx={{ borderRadius: 10, padding: 4 }}>
                  <h3 style={{ textAlign: "center", marginTop: -10 }}>
                    Subscriptions
                  </h3>
                  <LineChart
                    data={businessStats.subscriptions}
                    label="Subscriptions"
                    height={200}
                  />
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card sx={{ borderRadius: 10, padding: 4 }}>
                  <h3 style={{ textAlign: "center", marginTop: -10 }}>
                    Redemptions
                  </h3>
                  <LineChart
                    data={businessStats.redemptions}
                    label="Redemptions"
                    height={200}
                  />
                </Card>
              </Grid>
            </Grid>
          )}
        </Grid>

        {accountSetup && (
          <Grid item md={10} xs={11} container justifyContent="center" mt={3}>
            <Card sx={{ borderRadius: 10, padding: 4, width: "100%" }}>
              <h3 style={{ textAlign: "center", marginTop: -10 }}>
                Transactions
              </h3>
              <ConnectComponentsProvider
                connectInstance={stripeConnectInstance}
              >
                <ConnectPayments />
              </ConnectComponentsProvider>
            </Card>
          </Grid>
        )}
        {accountSetup && (
          <Grid item xs={11} container justifyContent="center" my={3}>
            <Card sx={{ borderRadius: 10, padding: 4 }}>
              <img
                src="/payment.png"
                width="100%"
                height={100}
                style={{ objectFit: "contain" }}
              />
              <Grid item>
                <LoadingButton
                  loading={requestingPayout}
                  onClick={async () => {
                    try {
                      setRequestingPayout(true);
                      let res = await axios.post("/api/payout");
                      toast.success(
                        user.earningsToPay > 0
                          ? "Payout request sent"
                          : "Accessing Payment Dashboard"
                      );
                      await new Promise((resolve) => setTimeout(resolve, 2000));
                      window.open(res.data.url, "_self");
                      fetchUser();
                      setRequestingPayout(false);
                    } catch (error) {
                      toast.error("There was an error requesting the payout");
                      console.log(error);
                      setRequestingPayout(false);
                    }
                  }}
                  color="success"
                  variant="outlined"
                >
                  {user.earningsToPay > 0
                    ? "Request Payout"
                    : "Access Payments Dashboard"}
                </LoadingButton>
              </Grid>
            </Card>
          </Grid>
        )}
        <Grid item container justifyContent="center" xs={12}>
          <Alert
            severity="warning"
            sx={{
              borderRadius: 10,
            }}
          >
            <p>
              <strong>Pending Payouts</strong> - Please note that it may take up
              to 20 days for your revenue to be ready for payouts.
            </p>
          </Alert>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          {/* Earnings and Payouts */}
          {earningCard(
            "Total Earnings",
            Number(user?.earningsTotal / 100).toFixed(2),
            "#77DD77"
          )}
          {earningCard(
            "Pending Payouts",
            Number(user?.earningsPending / 100).toFixed(2),
            "#FFB347"
          )}
          {earningCard(
            "Available For Payout",
            Number(user?.earningsToPay / 100).toFixed(2),
            "#FF6961"
          )}
          {earningCard(
            "Paid Payouts",
            Number(user?.earningsPaid / 100).toFixed(2),
            "#77DD77"
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
