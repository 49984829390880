import { useEffect } from "react";

import { Grid, Button, TextField, Box, Card } from "@pankod/refine-mui";
import Anim from "components/Anim";

import { useLogin, useLogout } from "@pankod/refine-core";
import { useForm } from "react-hook-form";

export default function Login() {
  const { mutate: login } = useLogin();
  const { mutate: logout } = useLogout();

  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);

  const onSubmit = async (formData) => {
    logout();
    await login({
      username: formData.username,
      password: formData.password,
    });
  };

  const { register, handleSubmit } = useForm();

  useEffect(() => {
    localStorage.setItem("user-type", "owner");
  }, []);

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        alignItems="center"
        style={{
          height: "100vh",
        }}
      >
        <Grid
          item
          xs={11}
          container
          justifyContent="center"
          alignItems="center"
          rowSpacing={3}
        >
          <Grid item md={5} xs={12} container justifyContent="center">
            <Anim dir="left">
              <Card sx={{ borderRadius: "50%" }}>
                <Grid container spacing={3} p={3}>
                  <Grid item xs={12} container justifyContent="center">
                    <img
                      src="/logo.png"
                      alt="Logo"
                      style={{ height: 200, objectFit: "contain" }}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Anim>
          </Grid>
          <Grid item md={6} xs={12} container justifyContent="center">
            <Anim dir="right">
              <Card
                sx={{
                  borderBottom: 0,
                  padding: 3,
                  minWidth: "33vw",
                  borderRadius: 7,
                }}
              >
                <Grid item container justifyContent="center">
                  <h1 level={3}>Login</h1>
                </Grid>
                <Box
                  component="form"
                  onSubmit={handleSubmit(onSubmit)}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <TextField
                    {...register("username", { required: true })}
                    label="Email"
                    autoComplete="email"
                    autoFocus
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    {...register("password", { required: true })}
                    label="Password"
                    style={{ marginBottom: "12px" }}
                    type="password"
                    suggested="current-password"
                    placeholder="●●●●●●●●"
                  />
                  <div style={{ marginBottom: "12px" }}>
                    <a
                      style={{
                        float: "right",
                        fontSize: "14px",
                        color: "#00bcd4",
                      }}
                      href={
                        "/register" +
                        (params.get("to") ? "?to=" + params.get("to") : "")
                      }
                    >
                      Don't have an account? Register here
                    </a>
                  </div>
                  <div style={{ marginBottom: "12px" }}>
                    <a
                      style={{
                        float: "right",
                        fontSize: "12px",
                        color: "#00bcd4",
                      }}
                      href="/forgot-password"
                    >
                      Password Forgot?
                    </a>
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={() => {
                      handleSubmit(onSubmit)();
                    }}
                  >
                    Login
                  </Button>
                </Box>
              </Card>
            </Anim>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
