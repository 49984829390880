import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { gsap } from "gsap";
import { set } from "date-fns";

const Anim = ({
  children,
  wrapper = "div",
  dir = null,
  delay = 0,
  duration = 1,
  ...props
}) => {
  let compRef = useRef(null);
  const [componentRef, setComponentRef] = useState(null);
  const [played, setPlayed] = useState(false);

  const Component = wrapper;
  const distance = 200;
  let fadeDirection;
  switch (dir) {
    case "left":
      fadeDirection = { x: -distance };
      break;
    case "right":
      fadeDirection = { x: distance };
      break;
    case "up":
      fadeDirection = { y: distance };
      break;
    case "down":
      fadeDirection = { y: -distance };
      break;
    default:
      fadeDirection = { y: distance };
  }

  useLayoutEffect(() => {
    if (!componentRef && compRef.current) {
      setComponentRef(compRef.current);
    }
  }, [compRef]);

  useEffect(() => {
    if (componentRef && !played) {
      let ctx = gsap.context(() => {
        // all your GSAP animation code here
        gsap.from(compRef.current, {
          ...fadeDirection,
          opacity: 0,
          delay,
          duration: duration,
          onComplete: () => {
            setPlayed(true);
          },
        });
      });
      return () => ctx.revert(); // <- cleanup!
    }
  }, [componentRef, fadeDirection, delay, duration]);

  return (
    <Component ref={compRef} {...props}>
      {children}
    </Component>
  );
};

export default Anim;
