import { useState, useEffect } from "react";

import { useParams } from "@pankod/refine-react-router-v6";
import {
  Grid,
  Card,
  Divider,
  Typography,
  Button,
  Icon,
  IconButton,
} from "@pankod/refine-mui";
import { LoadingButton } from "@pankod/refine-mui";
import Loading from "react-loading-components";
import NavBar from "components/Navbar";
import ReactMarkdown from "react-markdown";

import axios from "utils/axios";
import { API_URL, APP_URL } from "constants";
import { toast } from "react-toastify";
import useAuth from "contexts/AuthContext";

import StrapiImage from "components/strapi-image";

export default function PackView(props) {
  const { id } = useParams();
  const { user } = useAuth();

  const [loading, setLoading] = useState(true);
  const [loadingSession, setLoadingSession] = useState(false);
  const [shop, setShop] = useState(null);
  const [pack, setPackage] = useState(null);

  const loadPackData = async (packageID) => {
    try {
      setLoading(true);
      let { data } = await axios.get("/api/packages/" + packageID);
      if (data) {
        if (data.active) {
          setPackage(data);
          let { data: shopData } = await axios.get(
            "/api/shop-data/" + data.shop.shop_id
          );
          setShop(shopData);
          setLoading(false);
        } else {
          window.open("/not-found", "_self");
        }
      }
    } catch (error) {
      window.open("/not-found", "_self");
    }
  };

  useEffect(() => {
    if (id) loadPackData(id);
  }, [id]);

  if (loading)
    return (
      <Grid
        container
        sx={{ height: "100vh" }}
        justifyContent="center"
        alignItems="center"
      >
        <Loading type="grid" width={100} height={100} fill="#8A56FF" />
      </Grid>
    );

  return (
    <Grid container sx={{ minHeight: "100vh" }} justifyContent="center">
      {shop && <NavBar title="LovYa" shopInfo={shop} />}
      <Grid
        item
        xs={12}
        sx={{
          mt: "68px",
        }}
      >
        {pack?.image && (
          <StrapiImage
            image={pack?.image}
            baseUrl={API_URL}
            styleDiv={{
              width: "100%",
              height: 200,
              position: "relative",
            }}
            styleImg={{
              objectFit: "cover",
            }}
          >
            <IconButton
              sx={{
                position: "absolute",
                top: 10,
                left: 10,
                backgroundColor: "white",
              }}
              onClick={() => {
                // go back to the shop
                window.open(APP_URL + "/shop/" + pack?.shop?.shop_id, "_self");
              }}
            >
              <Icon>arrow_back</Icon>
            </IconButton>
          </StrapiImage>
        )}
        {!pack?.image && (
          <img
            src={`/love-letter.png`}
            alt="logo"
            style={{
              width: "100%",
              height: 200,
              objectFit: "contain",
              marginTop: "10px",
            }}
          />
        )}
        <h1 style={{ textAlign: "center" }}>{`${pack?.name}`}</h1>
        <Divider sx={{ width: "100%", mb: 3 }} />
      </Grid>
      <Grid item xs={12} container justifyContent="center" sx={{ p: 2 }}>
        <ReactMarkdown>{`${pack?.description}`}</ReactMarkdown>
      </Grid>
      <Card sx={{ p: 2, m: 3, alignSelf: "flex-end" }}>
        <Typography>
          {`This package can be redeemed ${pack?.uses} time${
            pack.uses > 1 ? "s" : ""
          } each month`}
        </Typography>
        <Divider sx={{ width: "100%", mb: 1 }} />

        <LoadingButton
          variant="contained"
          fullWidth
          loading={loadingSession}
          disabled={!user}
          sx={{ float: "right" }}
          onClick={async () => {
            if (user && user?.role?.name == "Authenticated") {
              setLoadingSession(true);
              try {
                let res = await axios.get(
                  "/api/create-checkout-session/" + pack.id
                );
                window.open(res.data, "_self");
                setLoadingSession(false);
              } catch (error) {
                console.log(error);
                toast.error(
                  "Something went wrong whilst attempting the purchase, please try again later"
                );
                setLoadingSession(false);
              }
            } else {
              toast.error("You must be logged in to purchase a package");
            }
          }}
        >{`Buy For £${(Number(pack.cost) / 100).toFixed(2)}`}</LoadingButton>
      </Card>
    </Grid>
  );
}
