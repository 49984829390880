import { TOKEN_KEY } from "./constants";

import axios from "utils/axios";
import { toast } from "react-toastify";

export const authProvider = {
  login: async ({ username, password }) => {
    const { data, status } = await axios.post("/api/auth/local", {
      identifier: username,
      password,
    });

    if (status === 200) {
      // set header axios instance
      localStorage.setItem(TOKEN_KEY, data.jwt);
      axios.defaults.headers.common.Authorization = `Bearer ${data.jwt}`;

      let { data: userData } = await axios.get("/api/users/me");
      localStorage.setItem("user", JSON.stringify(userData));

      if (userData.role.name == "Authenticated") {
        toast.error("You are not authorized to access this page");
        delete axios.defaults.headers.common.Authorization;
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem("user");
        localStorage.removeItem("customer");
        return Promise.reject();
      }

      return Promise.resolve();
    }
    return Promise.reject();
  },
  register: async ({
    first_name,
    last_name,
    birth_date,
    phone,
    email,
    password,
    roleName,
    agentUID,
  }) => {
    const { data, status } = await axios.post("/api/auth/local/register", {
      first_name,
      last_name,
      birth_date,
      phone,
      email,
      password,
      roleName,
      agentUID,
    });
    if (status === 200) {
      // set header axios instance
      localStorage.setItem(TOKEN_KEY, data.jwt);
      axios.defaults.headers.common.Authorization = `Bearer ${data.jwt}`;

      let { data: userData } = await axios.get("/api/users/me");
      localStorage.setItem("user", JSON.stringify(userData));

      return Promise.resolve();
    }
    return Promise.reject();
  },
  logout: () => {
    delete axios.defaults.headers.common.Authorization;
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem("user");
    localStorage.removeItem("customer");
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);
    const auth = localStorage.getItem("user");
    if (auth && token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: async () => {
    const auth = localStorage.getItem("user");
    if (auth) {
      const parsedUser = JSON.parse(auth);
      return Promise.resolve(parsedUser.role.type);
    } else {
      try {
        let { data } = await axios.get("/api/users/me");
        return Promise.resolve(data.role.type);
      } catch (error) {
        return Promise.reject();
      }
    }
  },
  getUserIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return Promise.reject();
    }
    const { data, status } = await axios.get("/api/users/me");
    if (status === 200) {
      // const { id, username, email } = data;
      localStorage.setItem("user", JSON.stringify(data));
      return Promise.resolve(data);
    }

    return Promise.reject();
  },
};
